//#region IMPORTS
import React from "react";

//#endregion

export default function Registry() {
  return (
    <div id="registry-wrapper">
      <p>Hemsidan är under uppbyggnad, denna sida kommer senare.</p>
    </div>
  );
}
