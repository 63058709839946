//#region IMPORTS
import React from "react";
//#endregion

export default function History() {
  return (
    <div id="history-wrapper">
      <p>
        Julius goes the extra Miles to be with the love of his life, Holly, and
        indeed the extra Miles he goes, so far 64800 miles traveled.
      </p>
      <p>
        Julius går dem extra Miles för att få vara med sin livs kärlek, Holly,
        och javisst går han dem extra milesen, hittills 64800 miles (100000 km)
        berest.
      </p>
      <p>Hemsidan är under uppbyggnad, denna sida kommer senare.</p>
    </div>
  );
}
