//#region IMPORTS
import React from "react";
import { Formik, Form } from "formik";
import { MyField, MyTextArea } from "../components/formikExtensions";
import axios from "axios";
import "../styles/info.css";
//#endregion

const validate = (values) => {
  const errors = {};

  if (!values.firstName || values.firstName.trim() === "")
    errors.firstName = "Ange förnamn";

  if (!values.songRequest || values.songRequest.trim() === "")
    errors.songRequest = "Ange låt";

  return errors;
};

export default function Info() {
  return (
    // <div id="wrapper" style={{ backgroundImage: `url(${background})` }}>
    <div id="info-wrapper">
      <p>
        Varmt välkomna till vår vintriga bröllopsfest
        <br />
        den 18:e januari 2025, kl 13:00 - 19:00
        <br />
        <a href="https://www.jadersgarden.se/galleri-%26-om-oss">
          Jädersgården, Eskilstuna
        </a>
      </p>

      <p>
        Jädersgården ligger vid Kjula som är 15 km från Eskilstuna centrum.
        <br />
        Buss 208 stannar vid Kjula Skola som ligger 4 km från Jädersgården, så
        vi hoppas att alla gäster kan prata ihop sig och samåka med bilar till
        lokalen.
        <br />
        Hör av er om ni har några funderingar eller behöver hjälp med transport.
      </p>

      <p>Mer info kommer senare.</p>

      <Formik
        initialValues={{
          firstName: "",
          songRequest: "",
        }}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post("/api/songRequest", values)
            .then(function (response) {
              console.log(response);
              setSubmitting(false);
              alert("Tack för ditt låtval");
            })
            .catch(function (error) {
              console.log(error);
              setSubmitting(false);
            });
        }}
      >
        <Form id="songrequest-form">
          <p>Önska en låt du vill höra på bröllopsfesten</p>
          <MyField name="firstName" label={"Förnamn"} />

          <MyTextArea name="songRequest" label="Låt" />

          <button type="submit">Önska låt</button>
        </Form>
      </Formik>

      <iframe
        id="venue-map"
        height="400px"
        width="100%"
        title="Lokal plats"
        frameBorder="0"
        referrerPolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyA6RrkXm1Aa0p-8zE2q-lhLAPGXhkLxkNk&q=Föreningen+Jädersgården&zoom=10&language=sv"
        allowFullScreen
      ></iframe>
    </div>
  );
}
