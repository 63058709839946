//#region IMPORTS
import React from "react";
import { useAuth } from "../provider/authProvider";
import axios from "axios";
import { Formik, Form } from "formik";
import { MyField } from "../components/formikExtensions";

import "../styles/login.css";

//#endregion

const validate = (values) => {
  const errors = {};

  if (!values.firstname || values.firstname === "")
    errors.firstname = "Ange namn";

  return errors;
};

export default function Login() {
  const { setToken } = useAuth();

  return (
    <div id="login-wrapper">
      <Formik
        initialValues={{
          firstname: "",
        }}
        validate={validate}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post("/api/user/login", values)
            .then(function (response) {
              console.log(response);
              setToken(response.data.token);
              setSubmitting(false);
            })
            .catch(function (error) {
              console.log(error);
              setSubmitting(false);
            });
        }}
      >
        <Form>
          <MyField
            name="firstname"
            type="text"
            label="Vad är brudens förnamn?"
          />

          <button type="submit">Skicka</button>
        </Form>
      </Formik>
    </div>
  );
}
