//#region IMPORTS
import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "../styles/home.css";
import venueWinter from "../assets/jadersgarden-winter_2.jpg";
import cityhall from "../assets/cityhall-window.jpg";

//#endregion

function WhenWhereInfo() {
  return (
    <div id="when-where-info">
      <div>
        <h3>När</h3>
        <p>
          18e Januari, 2025
          <br />
          kl 13:00
        </p>
      </div>
      <div>
        <h3>Vart</h3>
        <p>
          Jädersgården
          <br />
          Eskilstuna
        </p>
      </div>
    </div>
  );
}

function RsvpButton() {
  return (
    <div id="rsvp-button-home_container" className="rsvp-button_container">
      <Link to="/rsvp" id="rsvp-button-home" className="rsvp-button">
        <div>Anmäl dig här</div>
      </Link>
    </div>
  );
}

export default function Home() {
  return (
    // <div id="wrapper" style={{ backgroundImage: `url(${background})` }}>
    <div id="home-wrapper">
      {!isMobile ? (
        <div className="inline-50">
          <div>
            <p id="welcome-message">
              Varmt välkomna till vår vintriga bröllopsfest!
            </p>

            <WhenWhereInfo />

            <RsvpButton />
          </div>
          <div>
            <img src={cityhall} id="cityhall" alt="Vigsel, stadshuset" />
          </div>
        </div>
      ) : (
        <div>
          <p id="welcome-message">
            Varmt välkomna till vår <br />
            vintriga bröllopsfest!
          </p>

          <img src={cityhall} id="cityhall" alt="Vigsel, stadshuset" />

          <WhenWhereInfo />

          <RsvpButton />
        </div>
      )}

      <img
        src={venueWinter}
        id="venue-winter"
        alt="Jädersgården utomhus, vinter"
      />
    </div>
  );
}
